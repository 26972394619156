export const xenaColor = {
	'ACCENT': '#30AFA6',
	'BLACK_3': 'rgba(0, 0, 0, 0.03)',
	'BLACK_6': 'rgba(0, 0, 0, 0.06)',
	'BLACK_12': 'rgba(0, 0, 0, 0.12)',
	'BLACK_24': 'rgba(0, 0, 0, 0.24)',
	'BLACK_26': 'rgba(0, 0, 0, 0.26)',
	'BLACK_38': 'rgba(0, 0, 0, 0.38)',
	'BLACK_54': 'rgba(0, 0, 0, 0.54)',
	'BLACK_87': 'rgba(0, 0, 0, 0.87)',
	'BLUE_PRIMARY': '#3366CC',
	'BLUE_PRIMARY_2': 'rgba(51, 102, 204, 0.2)',
	'ERROR': '#ff0000',
	'GRAY': '#eee',
	'GRAY_AVATAR': '#F0F0F0',
	'GRAY_DARK': '#E0E0E0',
	'GRAY_DARKEST': '#9E9E9E',
	'GRAY_LIGHT': '#f7f7f7',
	'GRAY_400': '#bdbdbd',
	'PRIMARY': '#1a535c',
	'PRIMARY_CONTRAST': '#ff6b6b',
	'WARNING': '#8d0000',
	'WHITE': '#ffffff',
};
